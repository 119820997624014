import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <section id="contact">
      <p>
        <span>E-mail: </span>
        <a
          href="mailto:vanessa.cassandra.s@gmail.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          vanessa.cassandra.s@gmail.com
        </a>
      </p>
      <p>
        <span>LinkedIn: </span>
        <a
          href="https://www.linkedin.com/in/vanessa-cassandra-0b567b25/"
          target="_blank"
          rel="noreferrer noopener"
        >
          https://www.linkedin.com/in/vanessa-cassandra-0b567b25/
        </a>
      </p>
      <p>
        <span>Github: </span>
        <a
          href="https://github.com/vancassa"
          target="_blank"
          rel="noreferrer noopener"
        >
          https://github.com/vancassa
        </a>
      </p>
      <p>
        <span>Twitter: </span>
        <a
          href="https://twitter.com/vancassa"
          target="_blank"
          rel="noreferrer noopener"
        >
          https://twitter.com/vancassa
        </a>
      </p>
    </section>
  </Layout>
)

export default Contact
